<template>
  <div :class="$style.root">
    <VCard elevation="0" :loading="loading">
      <VCardTitle v-if="label">{{ label }}</VCardTitle>
      <VCardText>
        <slot />
      </VCardText>
    </VCard>
  </div>
</template>

<script>
export default {
  name: 'SectionCard',
  props: {
    label: { type: String },
    loading: { type: Boolean }
  }
}
</script>

<style module lang="scss">
.root {
  :global(.v-card) {
    border: 1px solid #dee2e6;
  }
  &:not(:first-child) {
    margin-top: 20px;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
