<template>
  <AccountUsersApplication :id="id"/>
</template>
<script>

import AccountUsersApplication from '@/components/account-users/AccountUsers/components/AccountUsersApplication.vue';

export default {
  name: 'AccountUsersApplicationView',
  components: { AccountUsersApplication },
  props: {
    id: { type: String },
  }
};
</script>
